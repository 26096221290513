.App {
  text-align: center;
}

body {
  font-family: "Lucida Sans Typewriter", "Lucida Console", Monaco, "Bitstream Vera Sans Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  color: wheat;
}

body a{
  color: #551A8B;
}

.slide {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slide > * {
  margin: 1em;
}

.logo{
  height: 100%;
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: row;
  width: 400vw; /*400*/
  transform: rotate(90deg) translateY(-100vh);
  transform-origin: top left;
  background: url("assets/potential_backgrounds/bkg2.png");
  background-size: auto 100vh;
  background-repeat: repeat-x;
}

.outer-wrapper {
  width: 100vh;
  height: 100vw;
  transform: rotate(-90deg) translateX(-100vh);
  transform-origin: top left;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* .imgWrapper{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.imgArea{
  position: relative;
  width: 75vw;
  height: 55vh;
  box-shadow:
  2.3px 2.3px 3.5px rgba(0, 0, 0, 0.039),
  5.4px 5.4px 8.1px rgba(0, 0, 0, 0.056),
  9.7px 9.7px 14.5px rgba(0, 0, 0, 0.069),
  16.1px 16.1px 24.1px rgba(0, 0, 0, 0.081),
  26.5px 26.5px 39.7px rgba(0, 0, 0, 0.094),
  46.2px 46.2px 69.3px rgba(0, 0, 0, 0.111),
  100px 100px 150px rgba(0, 0, 0, 0.15)
;
  border-radius: 12px;
}

.imgArea img{
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  border: solid #271f31 1px;
  transition: opacity 1s ease-in-out;
}

.imgArea img.imgFront:hover{
  opacity: 0;
}

::-webkit-scrollbar {
  display:none;
}

footer{
  position: absolute;
  bottom: 2.5%;
  left: 2.5%;
  width: 20%;
}

figure{
  display: flex;
  flex-direction: column;
  align-items: center;
}


#landingText{
  height: 60vh;
  width: 60vw;
}

.birdsOne, .birdsTwo, .birdsThree{
  position: absolute;
  height: 115%;
  width: 35%;
}

.birdsOne {
  left: -5%;
}

.birdsTwo {
  left: 32.5%;
  transform: scaleX(-1);
}

.birdsThree {
  left: 72.5%;
}

#logoContainer{
  position: relative;
  width: 100%;
  height: 35%;
}